import { useEffect, useState } from 'react'

/**
 * https://observablehq.com/@werehamster/avoiding-hydration-mismatch-when-using-react-hooks 사이트 참고
 * mediaQueryString params 예시 - "(max-screen: 1024px)"
 */
export default function useMediaQuery(mediaQueryString: string) {
  const [isLoading, setIsLoading] = useState(true)
  const [matches, setMatches] = useState<boolean>(null)

  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQueryString)
    const listener = () => setMatches(!!mediaQueryList.matches)
    listener()
    setIsLoading(false)

    if (mediaQueryList.addEventListener) {
      mediaQueryList.addEventListener('change', listener)
      return () => mediaQueryList.removeEventListener('change', listener)
    }
    // safari12 등 addEventListener가 없는 브라우저 대응
    if (mediaQueryList.addListener) {
      mediaQueryList.addListener(listener)
      return () => mediaQueryList.removeListener(listener)
    }
  }, [mediaQueryString])

  return { isLoading, matches }
}
